import {IPromotionWithText, TRANSACTION_SUBTYPE} from '@reciclos/core';
import {Image} from 'react-native';
import {SvgProps} from 'react-native-svg';
import React from 'react';

export type ImageResource = {
  uri: string;
};

export type SvgResource = {
  uri: {
    default: React.FC<SvgProps>;
  };
};

export type SvgParamResource = {
  default: React.FC<SvgProps>;
};

const getResource = (required: number | string): ImageResource => {
  if (typeof required === 'number') {
    return Image.resolveAssetSource(required);
  }
  return {uri: required};
};

const getSvgResource = (required: SvgParamResource): SvgResource => ({uri: required});

const Images = {
  avant: {
    home: require('@assets/images/avant/AVANT-home.jpg'),
  },
  door_to_door: {
    deposit_types: {
      deposit_type_1: require('@assets/images/door-to-door/deposit_types/bag_house.png'),
      deposit_type_2: require('@assets/images/door-to-door/deposit_types/hook_bag.png'),
      deposit_type_3: require('@assets/images/door-to-door/deposit_types/bucket_house.png'),
      deposit_type_4: require('@assets/images/door-to-door/deposit_types/bucket_local.png'),
    },
  },
  languages: {
    es: require('@assets/svg/languages/es.svg'),
    ca: require('@assets/svg/languages/ca.svg'),
    va: require('@assets/svg/languages/va.svg'),
    eu: require('@assets/svg/languages/eu.svg'),
    gl: require('@assets/svg/languages/gl.svg'),
    en: require('@assets/svg/languages/en.svg'),
  },
  marketplace: {
    differentLocality: require('@assets/svg/marketplace/differentLocality.svg'),
    differentCCAA: require('@assets/svg/marketplace/differentCCAA.svg'),
    notReciclos: require('@assets/svg/marketplace/notReciclos.svg'),
    CCAA: {
      Andalucia: require('@assets/images/marketplace/CCAA/Andalucia.png'),
      Aragon: require('@assets/images/marketplace/CCAA/Aragon.png'),
      Asturias: require('@assets/images/marketplace/CCAA/Asturias.png'),
      Baleares: require('@assets/images/marketplace/CCAA/Baleares.png'),
      Canarias: require('@assets/images/marketplace/CCAA/Canarias.png'),
      Cantabria: require('@assets/images/marketplace/CCAA/Cantabria.png'),
      CastillaLaMancha: require('@assets/images/marketplace/CCAA/CastillaLaMancha.png'),
      CastillaLeon: require('@assets/images/marketplace/CCAA/CastillaLeon.png'),
      Catalunya: require('@assets/images/marketplace/CCAA/Catalunya.png'),
      Extremadura: require('@assets/images/marketplace/CCAA/Extremadura.png'),
      Ceuta: require('@assets/images/marketplace/CCAA/Ceuta.png'),
      Galicia: require('@assets/images/marketplace/CCAA/Galicia.png'),
      LaRioja: require('@assets/images/marketplace/CCAA/LaRioja.png'),
      Madrid: require('@assets/images/marketplace/CCAA/Madrid.png'),
      Melilla: require('@assets/images/marketplace/CCAA/Melilla.png'),
      Murcia: require('@assets/images/marketplace/CCAA/Murcia.png'),
      Navarra: require('@assets/images/marketplace/CCAA/Navarra.png'),
      PaisVasco: require('@assets/images/marketplace/CCAA/PaisVasco.png'),
      Valencia: require('@assets/images/marketplace/CCAA/Valencia.png'),
    },
  },
  mgm: {
    mgm_background: require('@assets/images/mgm/mgm_background.jpg'),
  },
  new_waste_containers: {
    blau: require('@assets/svg/new-waste-containers/papelera-azul.svg'),
    gris: require('@assets/svg/new-waste-containers/papelera-gris.svg'),
    taronja: require('@assets/svg/new-waste-containers/papelera-naranja.svg'),
    groc: require('@assets/svg/new-waste-containers/papelera-amarilla.svg'),
    verd: require('@assets/svg/new-waste-containers/papelera-verde.svg'),
    sin: require('@assets/svg/new-waste-containers/papelera-sin.svg'),
  },
  onboarding: {
    onboarding: require('@assets/images/onboarding/onboarding.png'),
    contenedores: require('@assets/images/onboarding/contenedores.png'),
    maquinas: require('@assets/images/onboarding/maquinas.png'),
    smartring: require('@assets/images/onboarding/smartring.png'),
    doorToDoor: require('@assets/images/onboarding/doorToDoor.png'),
    walkthrough: {
      container0: require('@assets/images/onboarding/walkthrough/container0.png'),
      container1: require('@assets/images/onboarding/walkthrough/container1.png'),
      container2: require('@assets/images/onboarding/walkthrough/container2.png'),
      container3: require('@assets/images/onboarding/walkthrough/container3.png'),
      container4: require('@assets/images/onboarding/walkthrough/container4.png'),
      doorToDoor0: require('@assets/images/onboarding/walkthrough/doorToDoor0.png'),
      doorToDoor1: require('@assets/images/onboarding/walkthrough/doorToDoor1.png'),
      doorToDoor2: require('@assets/images/onboarding/walkthrough/doorToDoor2.png'),
      doorToDoor3: require('@assets/images/onboarding/walkthrough/doorToDoor3.png'),
      smartbin0: require('@assets/images/onboarding/walkthrough/smartbin0.png'),
      smartbin1: require('@assets/images/onboarding/walkthrough/smartbin1.png'),
      smartbin2: require('@assets/images/onboarding/walkthrough/smartbin2.png'),
      smartbin3: require('@assets/images/onboarding/walkthrough/smartbin3.png'),
      smartbin4: require('@assets/images/onboarding/walkthrough/smartbin4.png'),
      smartring1: require('@assets/images/onboarding/walkthrough/smartring1.png'),
      smartring2: require('@assets/images/onboarding/walkthrough/smartring2.png'),
      smartring3: require('@assets/images/onboarding/walkthrough/smartring3.png'),
      smartring4: require('@assets/images/onboarding/walkthrough/smartring4.png'),
      smartring5: require('@assets/images/onboarding/walkthrough/smartring5.png'),
    },
  },
  permissions: {
    locationRequired: require('@assets/svg/permissions/location_required.svg'),
  },
  promotions: {
    earthDay: require('@assets/images/promotions/earth_day.png'),
    environmentDay: require('@assets/images/promotions/environment_day.png'),
    recycleDay: require('@assets/images/promotions/recycle_day.png'),
    beachDay: require('@assets/images/promotions/beach_day.png'),
    mountainDay: require('@assets/images/promotions/mountain_day.png'),
    greenDay: require('@assets/images/promotions/green_day.png'),
  },
  recycle: {
    doorToDoor: require('@assets/images/recycle/doorToDoor.png'),
    qrContainer: require('@assets/images/recycle/qrContainer.png'),
    avantContainer: require('@assets/images/recycle/avantContainer.png'),
    smartBinContainer: require('@assets/images/recycle/qrContainer.png'),
    bottle: require('@assets/images/recycle/bottle.png'),
    bottleAlert: require('@assets/images/recycle/bottleAlert.png'),
    can: require('@assets/images/recycle/can.png'),
    canAlert: require('@assets/images/recycle/canAlert.png'),
    home: require('@assets/images/recycle/home.png'),
    home_active: require('@assets/images/recycle/home_active.png'),
    door_to_door: require('@assets/images/recycle/door_to_door.png'),
    door_to_door_active: require('@assets/images/recycle/door_to_door_active.png'),
    smartbin: require('@assets/images/recycle/smartbin.png'),
    smartbin_active: require('@assets/images/recycle/smartbin_active.png'),
    smartring: require('@assets/images/recycle/smartring.png'),
    smartring_active: require('@assets/images/recycle/smartring_active.png'),
    avant: {
      bucket: require('@assets/images/recycle/avant/bucket.png'),
      fix: require('@assets/images/recycle/avant/fix.png'),
      iglu: require('@assets/images/recycle/avant/iglu.png'),
      locating: require('@assets/images/recycle/avant/locating.gif'),
      mailbox: require('@assets/images/recycle/avant/mailbox.png'),
      wheels: require('@assets/images/recycle/avant/wheels.png'),
      reciclito: require('@assets/images/recycle/avant/reciclito.gif'),
      info4wheels: require('@assets/images/recycle/avant/info4wheels.png'),
      infobucket: require('@assets/images/recycle/avant/infobucket.png'),
      infofix: require('@assets/images/recycle/avant/infofix.png'),
      infoiglu: require('@assets/images/recycle/avant/infoiglu.png'),
      infomailbox: require('@assets/images/recycle/avant/infomailbox.png'),
      avant_container_active: require('@assets/images/recycle/avant/avant_container_active.png'),
      avant_container: require('@assets/images/recycle/avant/avant_container.png'),
    },
  },
  redeem: {
    muchas_gracias: require('@assets/svg/redeem/muchas_gracias.svg'),
    not_accepted_images: require('@assets/images/redeem/not-accepted-images.jpg'),
    retree: {
      fertilize: require('@assets/svg/redeem/retree/fertilize.svg'),
      plant: require('@assets/svg/redeem/retree/plant.svg'),
      transport: require('@assets/svg/redeem/retree/transport.svg'),
      water: require('@assets/svg/redeem/retree/water.svg'),
      completed: require('@assets/svg/redeem/retree/completed.svg'),
      abonar_gif: require('@assets/images/redeem/retree/abonar.gif'),
      plantar_gif: require('@assets/images/redeem/retree/plantar.gif'),
      regar_gif: require('@assets/images/redeem/retree/regar_gif.gif'),
      transplantar_gif: require('@assets/images/redeem/retree/trasplantar.gif'),
      transplanted: require('@assets/images/redeem/retree/transplanted.jpg'),
      enviorenment_need_you: require('@assets/images/redeem/retree/enviorenment_need_you.png'),
      reciclos_insuficientes: require('@assets/svg/redeem/retree/reciclos_insuficientes.svg'),
    },
  },
  register: {
    welcome_register: require('@assets/images/register/welcome_register.jpg'),
    register_where: require('@assets/svg/register/register_where.svg'),
    register_high_five: require('@assets/svg/register/register_high_five.svg'),
    register_sofa: require('@assets/svg/register/register_sofa.svg'),
  },
  retree: {
    sow: require('@assets/images/retree/sow.png'),
    rafiki: require('@assets/images/retree/rafiki.png'),
    clean: require('@assets/images/retree/clean.png'),
    build: require('@assets/images/retree/build.png'),
    collect: require('@assets/images/retree/collect.png'),
    default: require('@assets/images/retree/default.png'),
    video_retree: require('@assets/images/retree/video_retree.png'),
  },
  smart_bins: {
    i_1: require('@assets/images/smart-bins/1.jpg'),
    i_2: require('@assets/images/smart-bins/2.jpg'),
    i_3: require('@assets/images/smart-bins/3.jpg'),
    i_4: require('@assets/images/smart-bins/4.jpg'),
    i_5: require('@assets/images/smart-bins/5.jpg'),
    iconSmartBinSmall: require('@assets/images/smart-bins/iconSmartBinSmall.png'),
    splash_smartbin_feedback: require('@assets/images/smart-bins/splash_smartbin_feedback.png'),
  },
  smart_rings: {
    srDepositPackages: require('@assets/svg/smart-rings/smart-ring-deposit-packages.svg'),
    smartringNoTecnologico: {
      tarjeta: require('@assets/images/smart-rings/sr-no-tecnologico/tarjeta.png'),
      sumaPuntos: require('@assets/images/smart-rings/sr-no-tecnologico/sumaPuntos.png'),
      depositaEnvases: require('@assets/images/smart-rings/sr-no-tecnologico/depositaEnvases.png'),
    },
  },
  walkthrough: {
    queEsReciclos: require('@assets/images/walkthrough/queEsReciclos.png'),
  },
  add_black: require('@assets/images/add_black.png'),
  markerContenedorDeReciclaje: require('@assets/images/container_marker.png'),
  contenedorTemporal: require('@assets/images/contenedorTemporal.png'),
  news_default: require('@assets/images/news-default.png'),
  noImage: require('@assets/images/noImage.png'),
  reciclosLoading: require('@assets/images/reciclosLoading.png'),
  ticket: require('@assets/images/ticket.png'),
  red_cross: require('@assets/images/red_cross.png'),
  reciclosLogo: require('@assets/svg/reciclos_logo.svg'),
  app_store_large: require('@assets/images/app_store_large.png'),
  google_play_large: require('@assets/images/google_play_large.png'),
  happyEmoji: require('@assets/svg/happy_emoji.svg'),
  seriousEmoji: require('@assets/svg/serious_emoji.svg'),
  angryEmoji: require('@assets/svg/angry_emoji.svg'),
  angryEmoji_selected: require('@assets/svg/angry_emoji_selected.svg'),
  happyEmoji_selected: require('@assets/svg/happy_emoji_selected.svg'),
  seriousEmoji_selected: require('@assets/svg/serious_emoji_selected.svg'),
  star: require('@assets/svg/star.svg'),
  tick: require('@assets/images/tick.png'),
  botella: require('@assets/images/botella.png'),
  lata: require('@assets/images/lata.png'),
  popup_oxidacion: require('@assets/images/popup_oxidacion.png'),
  discontinued_pwa: require('@assets/images/discontinuedPWA.jpg'),
  circularTrust: require('@assets/images/circular_trust.png'),
  circularTrustWhite: require('@assets/images/circular_trust_white.png'),
  limitDayBottle: require('@assets/images/limit_day_bottle.png'),
  video_app: require('@assets/images/video_app.jpg'),
  video_target: require('@assets/images/video_target.jpg'),
  welcome_background: require('@assets/svg/welcome_background.svg'),
  updateTerms: require('@assets/svg/updateTerms.svg'),
};

const communityImageByRegionId: {[key: number]: any} = {
  1: Images.marketplace.CCAA.Andalucia,
  2: Images.marketplace.CCAA.Aragon,
  3: Images.marketplace.CCAA.Cantabria,
  4: Images.marketplace.CCAA.CastillaLaMancha,
  5: Images.marketplace.CCAA.CastillaLeon,
  6: Images.marketplace.CCAA.Catalunya,
  7: Images.marketplace.CCAA.Ceuta,
  8: Images.marketplace.CCAA.Madrid,
  9: Images.marketplace.CCAA.Navarra,
  10: Images.marketplace.CCAA.Valencia,
  11: Images.marketplace.CCAA.Extremadura,
  12: Images.marketplace.CCAA.Galicia,
  13: Images.marketplace.CCAA.Baleares,
  14: Images.marketplace.CCAA.Canarias,
  15: Images.marketplace.CCAA.LaRioja,
  16: Images.marketplace.CCAA.PaisVasco,
  17: Images.marketplace.CCAA.Asturias,
  18: Images.marketplace.CCAA.Murcia,
  19: Images.marketplace.CCAA.Melilla,
};

const getPromotionIconImage = (promotion: IPromotionWithText): any => {
  switch (promotion.subtype) {
    case TRANSACTION_SUBTYPE.DIA_DE_LA_TIERRA:
      return Images.promotions.earthDay;
    case TRANSACTION_SUBTYPE.DIA_DEL_MEDIOAMBIENTE:
      return Images.promotions.environmentDay;
    case TRANSACTION_SUBTYPE.DIA_MUNDIAL_DEL_RECICLAJE:
      return Images.promotions.recycleDay;
    case TRANSACTION_SUBTYPE.DIA_DE_LAS_PLAYAS:
      return Images.promotions.beachDay;
    case TRANSACTION_SUBTYPE.DIA_DE_LOS_MONTES:
      return Images.promotions.mountainDay;
    case TRANSACTION_SUBTYPE.GREEN_WEEK:
      return Images.promotions.greenDay;
    case TRANSACTION_SUBTYPE.DONACIONES_AUTONOMICAS_ESPECIALES:
      if (promotion.regionId) {
        return communityImageByRegionId[promotion.regionId];
      }
      return undefined;
    default:
      return undefined;
  }
};

export {Images, getPromotionIconImage, getResource, getSvgResource};
