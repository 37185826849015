/**
 * All the strings MUST BE UNIQUE
 */

export enum GENERIC_ACTIONS {
  SET_SERVER_DATA_LOADING = 'GENERIC/SET_SERVER_DATA_LOADING',
  SET_SERVER_DATA_REFRESHING = 'GENERIC/SET_SERVER_DATA_REFRESHING',
  SET_SERVER_DATA_LOADING_MORE = 'GENERIC/SET_SERVER_DATA_LOADING_MORE',
  SET_SERVER_DATA = 'GENERIC/SET_SERVER_DATA',
  RESET_SERVER_DATA = 'GENERIC/RESET_SERVER_DATA',
  DESTROY_SESSION = 'GENERIC/DESTROY_SESSION',
}

export enum AUTH_ACTIONS {
  SET_REGISTER_PARAM = 'AUTH/SET_REGISTER_PARAM',
  SET_PENDING_VERIFICATION_PHONE = 'AUTH/SET_PENDING_VERIFICATION_PHONE',
  SET_ACCESS_TOKEN_INFO = 'AUTH/SET_ACCESS_TOKEN_INFO',
  SET_USER_INFO = 'AUTH/SET_USER_INFO',
  SET_USER_LOCATION = 'AUTH/SET_USER_LOCATION',
  SET_USER_TERMS = 'AUTH/SET_USER_TERMS',
  SET_DEMO_VERIFIED_LINK = 'AUTH/SET_DEMO_VERIFIED_LINK',
  SET_PENDING_WELCOME_RECICLOS_MESSAGE = 'AUTH/SET_PENDING_WELCOME_RECICLOS_MESSAGE',
  SET_SHOW_REGISTRATION_MAIL = 'AUTH/SET_SHOW_REGISTRATION_MAIL',
  SET_DESTINATION_STATUS = 'AUTH/SET_DESTINATION_STATUS',
  SET_IS_AVANT = 'AUTH/SET_IS_AVANT',
  SET_LOCATION_COORDINATES = 'AUTH/SET_LOCATION_COORDINATES',
  CLEAR_LOCATION_COORDINATES = 'AUTH/CLEAR_LOCATION_COORDINATES',
  SET_LOCATION_COMMUNITY = 'AUTH/SET_LOCATION_COMMUNITY',
  SET_LOCATION_COMMUNITY_NAME = 'AUTH/SET_LOCATION_COMMUNITY_NAME',
  SET_IS_RELOADING = 'AUTH/SET_IS_RELOADING',
  WS_LOGIN = 'AUTH/WS_LOGIN',
  WS_REGISTER = 'AUTH/WS_REGISTER',
  WS_FORGOT_PASSWORD = 'AUTH/WS_FORGOT_PASSWORD',
  WS_VALIDATE = 'AUTH/WS_VALIDATE',
  WS_RESEND_SMS = 'AUTH/WS_RESEND_SMS',
  WS_IS_VALID = 'AUTH/WS_IS_VALID',
  WS_RESET_PASSWORD = 'AUTH/WS_RESET_PASSWORD',
  WS_ME = 'AUTH/ME',
}

export enum LOTTERY_ACTIONS {
  WS_LIST_BY_STATUS = 'LOTTERY/WS_LIST_BY_STATUS',
  WS_GET = 'LOTTERY/WS_GET',
  WS_CLAIM_PRIZE = 'LOTTERY/WS_CLAIM_PRIZE',
  WS_BUY_NUMBERS = 'LOTTERY/WS_BUY_NUMBERS',
  SET_LOTTERIES_PENDING_LOADING = 'LOTTERY/SET_LOTTERIES_PENDING_LOADING',
  SET_LOTTERIES_PENDING = 'LOTTERY/SET_LOTTERIES_PENDING',
  SET_LOTTERIES_ACTIVE_LOADING = 'LOTTERY/SET_LOTTERIES_ACTIVE_LOADING',
  SET_LOTTERIES_ACTIVE = 'LOTTERY/SET_LOTTERIES_ACTIVE',
  SET_LOTTERIES_FINISHED_LOADING = 'LOTTERY/SET_LOTTERIES_FINISHED_LOADING',
  SET_LOTTERIES_FINISHED = 'LOTTERY/SET_LOTTERIES_FINISHED',
  SET_LOTTERY = 'LOTTERY/SET_LOTTERY',
  RESET_LOTTERY = 'LOTTERY/RESET_LOTTERY',
  UPDATE_LOTTERY_NUMBERS = 'LOTTERY/UPDATE_LOTTERY_NUMBERS',
  SET_HOME_FEATURED_INCENTIVE = 'LOTTERY/SET_HOME_FEATURED_INCENTIVE',
}

export enum MICRO_INCENTIVE_ACTIONS {
  WS_LIST_BY_STATUS = 'MICRO_INCENTIVE/WS_LIST_BY_STATUS',
  WS_GET = 'MICRO_INCENTIVE/WS_GET',
  WS_CLAIM_PRIZE = 'MICRO_INCENTIVE/WS_CLAIM_PRIZE',
  SET_MICRO_INCENTIVE = 'MICRO_INCENTIVE/SET_MICRO_INCENTIVE',
  RESET_MICRO_INCENTIVE = 'MICRO_INCENTIVE/RESET_MICRO_INCENTIVE',
  SET_ENABLED_SCANNER_QUEUE_IT = 'MICRO_INCENTIVE/SET_ENABLED_SCANNER_QUEUE_IT',
}

export enum DIRECT_PRIZE_ACTIONS {
  WS_LIST_BY_STATUS = 'DIRECT_PRIZE/WS_LIST_BY_STATUS',
  WS_GET = 'DIRECT_PRIZE/WS_GET',
  WS_CLAIM_PRIZE = 'DIRECT_PRIZE/WS_CLAIM_PRIZE',
  SET_DIRECT_PRIZE_OBJECTS = 'DIRECT_PRIZE/SET_DIRECT_PRIZE_OBJECTS',
  RESET_DIRECT_PRIZE = 'DIRECT_PRIZE/RESET_DIRECT_PRIZE',
}

export enum MICRO_INCENTIVE_PLAY_ACTIONS {
  WS_LIST_BY_STATUS = 'MICRO_INCENTIVE_PLAY/WS_LIST_BY_STATUS',
  WS_GET = 'MICRO_INCENTIVE_PLAY/WS_GET',
  WS_PLAY = 'MICRO_INCENTIVE_PLAY/WS_PLAY',
  WS_CLAIM_PRIZE = 'MICRO_INCENTIVE_PLAY/WS_CLAIM_PRIZE',
  SET_MICRO_INCENTIVE_PLAY = 'MICRO_INCENTIVE_PLAY/SET_MICRO_INCENTIVE_PLAY',
  RESET_MICRO_INCENTIVE_PLAY = 'MICRO_INCENTIVE_PLAY/RESET_MICRO_INCENTIVE_PLAY',
}

export enum DIRECT_INCENTIVE_ACTIONS {
  WS_GET = 'DIRECT_INCENTIVE_ACTIONS/WS_GET',
  WS_BUY = 'DIRECT_INCENTIVE_ACTIONS/WS_BUY',
  WS_CLAIM_PRIZE = 'DIRECT_INCENTIVE_ACTIONS/WS_CLAIM_PRIZE',
  SET_DIRECT_INCENTIVE = 'DIRECT_INCENTIVE_ACTIONS/SET_DIRECT_INCENTIVE',
  RESET_DIRECT_INCENTIVE = 'DIRECT_INCENTIVE_ACTIONS/RESET_DIRECT_INCENTIVE',
}

export enum RETREE_ACTIONS {
  WS_GET_INFO = 'RETREE_ACTIONS/WS_GET_INFO',
  SET_TREES_LIST = 'RETREE_ACTION/SET_TREES_LIST',
  SET_RETREE_STATS = 'RETREE_ACTION/SET_RETREE_STATE',
  SET_CURRENT_TREE = 'RETREE_ACTION/SET_CURRENT_TREE',
  SET_TOTAL_TREES = 'RETREE_ACTIONS/SET_TOTAL_TREES',
  SET_LOADING_STATS = 'RETREE_ACTIONS/LOADING_STATS',
  SET_PROCESSING_TREE = 'RETREE_ACTIONS/PROCESSING_TREE',
  SET_USER_TREES = 'RETREE_ACTIONS/SET_USER_TREES',
  SET_SHOW_DETAIL = 'RETREE_ACTIONS/SET_SHOW_DETAIL',
}

export enum MAGIC_ACTIONS {
  SET_NORMAL_DATA = 'MAGIC/SET_NORMAL_DATA',
  SET_NORMAL_DATA_2 = 'MAGIC/SET_NORMAL_DATA_2',
}

export enum UTILS_ACTIONS {
  SET_TABS_BACKGROUND_COLOR = 'UTILS/SET_TABS_BACKGROUND_COLOR',
  SET_APP_REVIEW_INFO = 'UTILS/SET_APP_REVIEW_INFO',
  SET_LOCALITY_NAME = 'UTILS/SET_LOCALITY_NAME',
}

export enum NUMBERS_ACTIONS {
  SET_LOTTERY_INFO = 'MAGIC/SET_LOTTERY_INFO',
}

export enum CROWD_ACTIONS {
  WS_GET = 'CROWD/WS_GET',
  WS_DONATE = 'CROWD/WS_DONATE',
  SET_CROWD = 'CROWD/SET_CROWD',
  SET_RECICLOS_DONATED_BY_USER = 'CROWD/SET_RECICLOS_DONATED_BY_USER',
  RESET_CROWD = 'CROWD/RESET_CROWD',
  SET_CROWD_BALANCE = 'CROWD/SET_CROWD_BALANCE',
}

export enum ALERT_ACTIONS {
  SHOW = 'ALERT/SHOW',
  SHOW_FIRST = 'ALERT/SHOW_FIRST',
  SHOW_NEXT = 'ALERT/SHOW_NEXT',
  SHOW_LOADING = 'ALERT/SHOW_LOADING',
}

export enum SMS_ACTIONS {
  SHOW = 'SMS/SHOW',
  HIDE = 'SMS/HIDE',
}

export enum COOKIES_ACTIONS {
  SHOW = 'COOKIES/SHOW',
  HIDE = 'COOKIES/HIDE',
  EXIST = 'COOKIES/EXIST',
  LOADING = 'COOKIES/LOADING',
}

export enum SNACKBAR_ACTIONS {
  SHOW = 'SNACKBAR/SHOW',
  HIDE = 'SNACKBAR/HIDE',
}

export enum BANNER_ACTIONS {
  SHOW = 'BANNER/SHOW',
  HIDE = 'BANNER/HIDE',
  UPDATE = 'BANNER/UPDATE',
}

export enum I18N_ACTIONS {
  SET_TRANSLATION_FUNCTION = 'I18N/SET_TRANSLATION_FUNCTION',
}

export enum COMMUNITY_ACTIONS {
  SET_LOADING = 'COMMUNITY/SET_LOADING',
  SET_LIST = 'COMMUNITY/SET_LIST',
}

export enum CONTAINER_ACTIONS {
  WS_NEAREST = 'CONTAINER/WS_NEAREST',
  WS_BOX = 'CONTAINER/WS_BOX',
  SET_CONTAINERS_LIST = 'CONTAINER/SET_CONTAINERS_LIST',
  RESET_CONTAINERS = 'CONTAINER/RESET_CONTAINERS',
}

export enum PERMISSIONS_ACTIONS {
  SET_CURRENT_PERMISSIONS = 'PERMISSIONS_ACTIONS/SET_CURRENT_PERMISSIONS',
}

export enum RECYCLE_PHOTO_ACTIONS {
  WS_BUCKET_CLEAN = 'RECYCLE_PHOTO/WS_BUCKET_CLEAN',
  WS_BUCKET_UPLOAD_IMAGE = 'RECYCLE_PHOTO/WS_BUCKET_UPLOAD_IMAGE',
  WS_BUCKET_INFO = 'RECYCLE_PHOTO/WS_BUCKET_INFO',
  WS_RECYCLE_INFO = 'RECYCLE_PHOTO/WS_RECYCLE_INFO',
  WS_PHOTOS_BY_STATUS = 'RECYCLE_PHOTO/WS_PHOTOS_BY_STATUS',
  WS_REVISION = 'RECYCLE_PHOTO/WS_REVISION',
  WS_DETAILS = 'RECYCLE_PHOTO/WS_DETAILS',
  WS_REMOVE = 'RECYCLE_PHOTO/WS_REMOVE',
  SET_RECYCLE_INFO = 'RECYCLE_PHOTO/SET_RECYCLE_INFO',
  SET_BUCKET_INFO = 'RECYCLE_PHOTO/SET_BUCKET_INFO',
  SET_BUCKET_DATA = 'RECYCLE_PHOTO/SET_BUCKET_DATA',
  SET_BUCKET_INCIDENCE_DATA = 'RECYCLE_PHOTO/SET_BUCKET_INCIDENCE_DATA',
  SET_BUCKET_REVISION_DATA = 'RECYCLE_PHOTO/SET_BUCKET_REVISION_DATA',
  SET_BUCKET_INVALID_DATA = 'RECYCLE_PHOTO/SET_BUCKET_INVALID_DATA',
  SET_LAST_BUCKET_THROW = 'RECYCLE_PHOTO/SET_LAST_BUCKET_THROW',
  SET_DOOR_TO_DOOR_IMAGE = 'RECYCLE_PHOTO/SET_DOOR_TO_DOOR_IMAGE',
  SET_SCAN_DATA = 'RECYCLE_PHOTO/SET_SCAN_DATA',
  SET_SCAN_INIT_DATA = 'RECYCLE_PHOTO/SET_SCAN_INIT_DATA',
}

export enum REDEEM_ACTIONS {
  SET_REDEEM_VIEW = 'REDEEM/SET_VIEW',
}
export enum RECYCLE_ACTIONS {
  SET_RECYCLE_VIEW = 'RECYCLE/SET_VIEW',
}

export enum TRANSACTION_ACTIONS {
  SET_TRANSACTION_VIEW = 'TRANSACTION/SET_VIEW',
}

export enum SESSION_BUCKET_ACTIONS {
  ADD_BUCKET_DATA = 'SESSION_BUCKET/ADD_BUCKET_DATA',
  UPDATE_BUCKET_DATA = 'SESSION_BUCKET/UPDATE_BUCKET_DATA',
  REMOVE_BUCKET_DATA = 'SESSION_BUCKET/REMOVE_BUCKET_DATA',
  RESET_BUCKET_DATA = 'SESSION_BUCKET/RESET_BUCKET_DATA',
}

export enum SMART_BIN_ACTIONS {
  WS_SMART_BIN_THROW = 'SMARTBIN/WS_SMART_BIN_THROW',
}

export enum USER_ACTIONS {
  SET_NORMAL_DATA = 'USER/SET_NORMAL_DATA',
}

export enum MGM_ACTIONS {
  WS_INFO = 'MGM_ACTIONS/WS_INFO',
  SET_MGM_VIEW = 'MGM/SET_VIEW',
}

export enum CONTACT_ACTIONS {
  WS_SEND_MESSAGE = 'CONTACT_ACTIONS/WS_SEND_MESSAGE',
}

export enum POST_USER_ACTIONS {
  SET_POST_USER = 'POST_USER_ACTIONS/SET_POST_USER',
  UPDATE_POST_AS_READ = 'POST_USER_ACTIONS/UPDATE_POST_AS_READ',
  UPDATE_POST_AS_DISCARDED = 'POST_USER_ACTIONS/UPDATE_POST_AS_DISCARDED',
  UPDATE_POST_AS_VIEWED = 'POST_USER_ACTIONS/UPDATE_POST_AS_VIEWED',
  UPDATE_POST_AS_SHARED = 'POST_USER_ACTIONS/UPDATE_POST_AS_SHARED',
}

export enum BOTTOM_SHEET_ACTIONS {
  SHOW = 'BOTTOM_SHEET/SHOW',
  SHOW_FIRST = 'BOTTOM_SHEET/SHOW_FIRST',
  QUEUE_NEXT = 'BOTTOM_SHEET/QUEUE_NEXT',
  HIDE = 'BOTTOM_SHEET/HIDE',
  SHOW_NEXT = 'BOTTOM_SHEET/SHOW_NEXT',
  SHOW_LOADING = 'BOTTOM_SHEET/SHOW_LOADING',
}

export enum QUEUE_IT_ACTIONS {
  SET_QUEUED = 'QUEUE_IT/SET_QUEUED',
  SET_QUEUE_STATE = 'QUEUE_IT/SET_QUEUE_STATE',
  SET_ENABLE = 'QUEUE_IT/SET_ENABLE',
  SET_EVENT_ID_MAIN = 'QUEUE_IT/SET_EVENT_ID_MAIN',
  SET_EVENT_ID_SCANNER = 'QUEUE_IT/SET_EVENT_ID_SCANNER',
}

export enum FEATURE_FLAG_ACTIONS {
  UPDATE = 'FEATURE_FLAG/UPDATE',
}

export enum PROMOTION_ACTIONS {
  SET_HAS_PROMOTIONS = 'PROMOTION/SET_HAS_PROMOTIONS',
}

/**
 * All actions for common type
 */
export type ALL_ACTIONS =
  | RECYCLE_PHOTO_ACTIONS
  | PERMISSIONS_ACTIONS
  | CONTAINER_ACTIONS
  | COMMUNITY_ACTIONS
  | I18N_ACTIONS
  | ALERT_ACTIONS
  | CROWD_ACTIONS
  | NUMBERS_ACTIONS
  | LOTTERY_ACTIONS
  | DIRECT_INCENTIVE_ACTIONS
  | DIRECT_PRIZE_ACTIONS
  | MICRO_INCENTIVE_ACTIONS
  | MICRO_INCENTIVE_PLAY_ACTIONS
  | RETREE_ACTIONS
  | AUTH_ACTIONS
  | GENERIC_ACTIONS
  | SNACKBAR_ACTIONS
  | BANNER_ACTIONS
  | SMS_ACTIONS
  | USER_ACTIONS
  | MGM_ACTIONS
  | CONTACT_ACTIONS
  | COOKIES_ACTIONS
  | SMART_BIN_ACTIONS
  | SESSION_BUCKET_ACTIONS
  | UTILS_ACTIONS
  | POST_USER_ACTIONS
  | BOTTOM_SHEET_ACTIONS
  | REDEEM_ACTIONS
  | QUEUE_IT_ACTIONS
  | FEATURE_FLAG_ACTIONS
  | PROMOTION_ACTIONS;

/**
 * All Reducers Names
 */
export enum REDUCER_NAMES {
  home = 'home',
  auth = 'auth',
  lotteries = 'lotteries',
  crowds = 'crowds',
  microIncentives = 'microIncentives',
  microIncentivesPlay = 'microIncentivesPlay',
  communities = 'communities',
  recyclePhoto = 'recyclePhoto',
  redeem = 'redeem',
  retree = 'retree',
  container = 'container',
  alert = 'alert',
  snackbar = 'snackbar',
  banner = 'banner',
  permissions = 'permissions',
  sms = 'sms',
  numbers = 'numbers',
  transactions = 'transactions',
  statistics = 'statistics',
  user = 'user',
  mgm = 'mgm',
  i18n = 'i18n',
  i18nState = 'i18nState',
  magic = 'magic',
  contact = 'contact',
  notifications = 'notifications',
  smartbins = 'smartbins',
  smartrings = 'smartrings',
  postUser = 'postUser',
  communication = 'communication',
  directIncentives = 'directIncentives',
  queueIt = 'queueIt',
  featureFlags = 'featureFlags',
  directPrizes = 'directPrizes',
  promotionalCodes = 'promotionalCodes',
  legalTexts = 'legalTexts',
  promotion = 'promotion',
}
