import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import {ImageSourcePropType} from 'react-native';
import {Image, ImageContentFit, ImageStyle} from 'expo-image';
import {getResource, Images} from '@utils/imagesUtils';
import {ImageURISource} from 'react-native/Libraries/Image/ImageSource';
import {ImageErrorEventData} from 'expo-image/src/Image.types';
import {ImageContentPosition} from 'expo-image/build/Image.types';

type Props = {
  source: ImageURISource;
  blurhash?: string;
  width?: number;
  height?: number;
  style?: ImageStyle | ImageStyle[] | undefined;
  resizeMode?: ImageContentFit;
  position?: ImageContentPosition;
  borderRadius?: number;
};

const ImageExpo: FC<PropsWithChildren<Props>> = props => {
  const [image, setImage] = useState<ImageSourcePropType | undefined>(undefined);
  const [width] = useState(props.width);
  const [height, setHeight] = useState(props.height || 1);

  // const remote = props.source.width === undefined && !props.source.uri?.startsWith('/static/');

  useEffect(() => {
    setImage(props.source);
  }, [props.source]);

  const loadFallback = (event: ImageErrorEventData) => {
    console.error('ERROR IMAGE', event.error);
    setImage(getResource(Images.noImage));
  };

  return (
    <Image
      style={[{width: width || '100%', height, borderRadius: props.borderRadius}, props.style]}
      source={image}
      placeholder={props.blurhash}
      contentFit={props.resizeMode || 'cover'}
      contentPosition={props.position}
      transition={props.blurhash ? 1000 : 0}
      onLoad={event => {
        if (height === 1) setHeight(event.source.height);
      }}
      onError={loadFallback}
      cachePolicy="memory-disk"
    />
  );
};

export default ImageExpo;
