import React from 'react';
import {NavigationContainer, getStateFromPath, useNavigationContainerRef} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import DiscontinuedPWAScreen from '@scenes/DiscontinuedPWAScreen';
import * as Linking from 'expo-linking';
import PrivacyPolicyWebAppScreen from '@scenes/policies/PrivacyPolicyScreenWebApp';
import StoreScreen from '@/scenes/StoreScreen';

// Crea un stack navigator
const Stack = createNativeStackNavigator();

const normalizePath = (path: string) => {
  if (typeof path === 'string') {
    return path.toLowerCase();
  }
  return path;
};

const ReciclosNavigatorWeb = () => {
  const navigatorRef = useNavigationContainerRef();
  const linking = {
    screens: {
      home: '',
      privatePolicy: 'privatePolicy',
      store: 'store',
    },
  };

  return (
    <NavigationContainer
      documentTitle={{enabled: false}}
      ref={navigatorRef}
      onReady={() => {
        console.debug('NavigationContainer Web App Ready');
      }}
      linking={{
        prefixes: [Linking.createURL('/')],
        config: linking,
        getStateFromPath: (path, config) => {
          const normalizedPath = normalizePath(path);
          return getStateFromPath(normalizedPath, config);
        },
      }}>
      <Stack.Navigator initialRouteName="home">
        <Stack.Screen name="home" component={DiscontinuedPWAScreen} options={{headerShown: false}} />
        <Stack.Screen name="privatePolicy" component={PrivacyPolicyWebAppScreen} options={{headerShown: false}} />
        <Stack.Screen name="store" component={StoreScreen} options={{headerShown: false}} />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default ReciclosNavigatorWeb;
